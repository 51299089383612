var easyTocPalcon = (function () {
    var isMobile = window.innerWidth < 991;

    var init = function () {
        var easyTocPalcon = document.getElementById('ez-toc-container');
        if (easyTocPalcon) {
            
            _addActiveTitleContainer(easyTocPalcon);
            var easyTocPalconWidth = easyTocPalcon.offsetWidth;
            var easyTocPalconHeight = 0;
            var ezHeaderHeight = easyTocPalcon.querySelector('.ez-toc-title-container').getBoundingClientRect().height;
            var ezNavHeight = 0;

            if (isMobile) {
                _setupMobile(easyTocPalcon);
            } else {
                easyTocPalconHeight = _setupDesktop(easyTocPalcon, easyTocPalconWidth);
                ezNavHeight = easyTocPalconHeight - ezHeaderHeight;
            }

            var easyTocPalconPos = easyTocPalcon.getBoundingClientRect().bottom + window.pageYOffset;
            var namesAndIds = _getNamesAndIds(easyTocPalcon);
            easyTocPalcon.querySelector('.ez-toc-active-title-container').addEventListener("click", function () {
                _expandNav(easyTocPalcon);
            });
            window.addEventListener("scroll", function () {
                raf(function () {
                    _stickyTop(easyTocPalcon, easyTocPalconPos, easyTocPalconHeight, ezNavHeight);
                    _spyScroll(easyTocPalcon, namesAndIds);
                });
            });

            easyTocPalcon.addEventListener('click', function(){
                if (easyTocPalcon.classList.contains('sticky')){
                    ga('send', 'event', 'TocEngagement', 'ClickOnSticky');
                }
            });            

            //Collapse TOC if clicking anywhere outside
            document.addEventListener("click", (evt) => {
                if (easyTocPalcon && easyTocPalcon.classList.contains('sticky') && easyTocPalcon.classList.contains('expanded')){
                    let targetElement = evt.target; // clicked element
                    do {
                        // This is a click inside. Do nothing, just return.
                        if (targetElement == easyTocPalcon) {
                            return;
                        }
                        // Go up the DOM
                        targetElement = targetElement.parentNode;
                    } while (targetElement);
                
                    // This is a click outside.
                    _expandNav(easyTocPalcon);
                }
            });

            _addScrollBar(easyTocPalcon);
        }
    };

    /**
     * @param easyTocPalcon
     * Adds the container of the active title display
     * Deletes Unnecessary element
     */
    var _addActiveTitleContainer = function (easyTocPalcon) {
        var elDelete = easyTocPalcon.querySelector('.ez-toc-title-toggle');
        elDelete.parentNode.parentNode.removeChild(elDelete.parentNode);
        var nextEl = easyTocPalcon.querySelector('nav');
        var activeTitleEl = document.createElement('div');
        activeTitleEl.className = 'ez-toc-active-title-container';
        activeTitleEl.innerHTML = '<p class="ez-toc-active-title"></p><i class="fas fa-angle-down"></i>';
        easyTocPalcon.insertBefore(activeTitleEl, nextEl);
    };

    /**
     * @param easyTocPalcon
     * Collapses or Expands the list of titles.
     */
    var _expandNav = function (easyTocPalcon) {
        easyTocPalcon.classList.toggle('expanded');
        _checkTitleToDisplay(easyTocPalcon);
    };

    /**-
     * Setup for mobile devices
     */
    var _setupMobile = function (easyTocPalcon) {
        easyTocPalcon.querySelector('.ez-toc-active-title-container .ez-toc-active-title').innerText = easyTocPalcon.querySelector('.ez-toc-title-container .ez-toc-title').innerText;
        document.querySelector('#ez-toc-container').style.top = _getHeaderHeight() + 'px';
    };

    /**
     * @param easyTocPalcon
     * @param easyTocPalconPos
     * Makes the TOC sticky to top.
     */
    var _stickyTop = function (easyTocPalcon, easyTocPalconPos, easyTocPalconHeight, ezNavHeight) {
        var isStuck = easyTocPalcon.classList.contains('sticky');
        var ezWrapper = document.getElementById('ez-toc-palcon-wrapper');
        var ezWrapperExists = document.body.contains(ezWrapper);

        if (isMobile) {
            easyTocPalconPos = 0;
        } else {
            easyTocPalcon.querySelector('nav').classList.add('no-transition');
        }
        if (window.pageYOffset > easyTocPalconPos && !isStuck) {
            if (!isMobile && ezWrapperExists) {
                ezWrapper.style.height = easyTocPalconHeight + 'px';
            }
            easyTocPalcon.classList.remove('expanded');
            isStuck = true;
            if (!isMobile) {
                setTimeout(function () { easyTocPalcon.classList.add('sticky'); }, 50);
            } else {
                easyTocPalcon.classList.add('sticky');
            }
        } else if (( window.pageYOffset + ezNavHeight ) <= easyTocPalconPos && isStuck) { //  window.pageYOffset + max-height of nav when #ez-toc-container.expanded
            easyTocPalcon.classList.remove('sticky');
            isStuck = false;
            if (!isMobile) {
                easyTocPalcon.classList.add('expanded');
                // https://stackoverflow.com/questions/5629684/how-can-i-check-if-an-element-exists-in-the-visible-dom/16820058#16820058
                if (ezWrapperExists) {
                    ezWrapper.style.removeProperty('height');
                }
            }
        } else { }

        if (!isMobile) {
            easyTocPalcon.querySelector('nav').classList.remove('no-transition');
        }
    };

    /**
     * @param easyTocPalcon
     * @param easyTocPalconWidth
     * Setup for bigger devices than mobile + Wraps the toc in order to avoid jumping when becoming sticky.
     */
    var _setupDesktop = function (easyTocPalcon, easyTocPalconWidth) {
        var wrapper = document.createElement('div');
        wrapper.classList.add('ez-toc-palcon-wrapper');
        wrapper.setAttribute('id', 'ez-toc-palcon-wrapper');
        wrapper.style.width = easyTocPalconWidth + "px";
        easyTocPalcon.style.width = easyTocPalconWidth + "px";
        var parent = easyTocPalcon.parentNode;
        parent.replaceChild(wrapper, easyTocPalcon);
        wrapper.appendChild(easyTocPalcon);
        easyTocPalcon.classList.add('expanded');
        return easyTocPalcon.getBoundingClientRect().height;
    };

    var _removeEventListeners = function (el) {
        elClone = el.cloneNode(true);
        el.parentNode.replaceChild(elClone, el);
        return elClone;
    }

    /**
     * Arranges a list of Names+Ids of titles
     * Adds class text-base to each link on list
     */
    var _getNamesAndIds = function (easyTocPalcon) {

        // this will not work in Internet Explorer
        // https://stackoverflow.com/questions/36810940/array-from-on-the-internet-explorer
        // Added the method described in this page to array-from.js and added it to the wp_enqueue_script
        var arrayElements = Array.from(easyTocPalcon.querySelectorAll('nav li a'));
        var namesAndIds = [];
        arrayElements.forEach(function (el) {
            el = _removeEventListeners(el);

            el.parentNode.classList.add('text-base');
            var listObj = {};
            titleName = el.title;
            titleId = el.hash.replace('#', '');
            listObj.titleId = titleId;
            listObj.titleName = titleName;
            namesAndIds.push(listObj);

            el.addEventListener("click", function (e) {
                e.preventDefault();
                let anchorElement = document.getElementById(e.target.hash.replace('#', ''));
                let pos;
                if (anchorElement) {
                    pos = anchorElement.getBoundingClientRect().top + window.pageYOffset;
                    var gap = window.innerWidth < 991 ? 120 : 60;
                    _scrollTo(pos, gap);
                    easyTocPalcon.classList.remove("sticky");
                }

                ga('send', 'event', 'TocEngagement', 'ClickOnExpanded', anchorElement.textContent);
            });
        });

        return namesAndIds;
    };

    /**
     *  Takes an array of the headers listed in the Easy TOC, and finds the index of the last one to cross
     *  the top of the window (give or take a specific offset distance, for UX reasons)
     *
     *  There are no problems with "negatives[0] > 0" because when the user is that high up the page, the sticky TOC
     *  doesn't even apply yet.
     *
     * @param namesAndIds array - They array of {name, id} objects from the TOC links.
     * @return int - the index of the most recent element to cross the top of the window
     */
    var _indexOfClosestAboveTop = function (namesAndIds) {

        // save all TOC elements' distance to the top of the window as an array
        var negatives = [];
        namesAndIds.forEach(function (nameAndId, i) {
            let currEl = document.getElementById(nameAndId.titleId);
            if (currEl) {
                let rect = currEl.getBoundingClientRect();
                let distFromTop = rect.top - 0.2 * window.innerHeight; // offset by 20%-of-screen-height for UX reasons
                negatives[i] = distFromTop;
            }
        });

        // We want the index of the last element from the array that crossed the top of the window.
        // We expect the distance-to-top-of-window to be a negative number for all elements above the window,
        //  so we use "absolute value" (Math.abs()) to measure it.
        //  Distances with a positive value must be excluded.
        let closestTo0 = 0;

        if (negatives[0] > 0) {
            closestTo0 = isMobile ? -1 : 0;
            return closestTo0;
        }

        negatives.forEach(function (num, i) {
            if (Math.abs(num) < Math.abs(negatives[closestTo0]) && num <= 0) {
                closestTo0 = i;
            }
        });

        return closestTo0;
    }

    /**
     * Enables a spyScroll in order to check which title is active in every scroll event
     * @param namesAndIds
     *
     */
    var _spyScroll = function (easyTocPalcon, namesAndIds) {
        const tocEl = easyTocPalcon.querySelectorAll('li a');
        const tocElArray = Array.from(tocEl);
        tocElArray.forEach(function (element) {
            element.classList.remove('active');
        });

        let theRealActivePleaseStandUp = _indexOfClosestAboveTop(namesAndIds);
        if ( theRealActivePleaseStandUp !== -1 ) {
            let anchorElement = easyTocPalcon.querySelector('li a[href="#' + namesAndIds[theRealActivePleaseStandUp].titleId + '"]');
            anchorElement.classList.add('active');
        }
        
        _checkTitleToDisplay(easyTocPalcon);
    };

    /**
     * Checks which title has to be shown in each scenario
     */
    var _checkTitleToDisplay = function (easyTocPalcon) {
        var titlePlaceholder = easyTocPalcon.querySelector('.ez-toc-active-title-container .ez-toc-active-title');
        var defaultTitle = easyTocPalcon.querySelector('.ez-toc-title-container .ez-toc-title').textContent;
        var activeAnchorElement = easyTocPalcon.querySelector('nav li a.active');
        var activeTitle = activeAnchorElement ? activeAnchorElement.textContent : null;
        var isExpanded = easyTocPalcon.classList.contains('expanded');
        if (isMobile) {
            if (activeAnchorElement) {
                titlePlaceholder.textContent = isExpanded ? defaultTitle : activeTitle;
            } else {
                titlePlaceholder.textContent = defaultTitle;
            }
        } else {
            if (activeAnchorElement) {
                titlePlaceholder.textContent = isExpanded ? '' : activeTitle;
            } else {
                titlePlaceholder.textContent = '';
            }
        }
    }

    /**
     * Gets the header height or children's header height (The bigger)
     */
    var _getHeaderHeight = function () {
        var header = document.getElementById('header');
        var headerChildren = Array.from(header.children);
        var headerChildrenHeight = headerChildren.reduce(function (totalHeight, child) {
            return totalHeight + child.offsetHeight;
        }, 0);
        var totalHeight = header.offsetHeight || headerChildrenHeight;

        return totalHeight;
    }

    /**
     * Adds custom scrollbar
     */
    var _addScrollBar = function () {
        if (typeof jQuery('#ez-toc-container nav').mCustomScrollbar === "function") {
            jQuery('#ez-toc-container nav').mCustomScrollbar({
                theme: "dark",
                autoHideScrollbar: false
            })    
        }
    };

    var _scrollTo = function (elPos, gap) {
        var top = elPos - gap;
        window.scrollTo({
            top: top,
            behavior: 'smooth'
        })
    };

    var raf = window.requestAnimationFrame || window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame || function (callback) {
            window.setTimeout(callback, 1000 / 60);
        };

    return {
        init: init
    }
})();

(function ($) {
    $(document).ready(function () {
        easyTocPalcon.init();
    });
})(jQuery);
